import ApiService from "./api.service";

const authRepository = {
    getAll: async (url, userName) => {
        url = url + `managerImage/getUserImages`;
        if (userName) {
            url += `?userName=${userName}`
        }
        return ApiService.get(url);
    },
    getAllForMember: async (url, userName) => {
        url = url + `memberReport/getUserImages`;
        if (userName) {
            url += `?userName=${userName}`
        }
        return ApiService.get(url);
    },
    upload: async (url, data) => {
        url = url + `managerImage/uploadImages`;
        return ApiService.upload(url, data);
    },
    delete: async (url, data) => {
        url = url + `managerImage/deleteImage`;
        return ApiService.delete(url, data);
    },
};

export default authRepository;
