<template>
    <div id="quan-ly-hinh-anh" class="quan-ly-hinh-anh">
        <b-row>
            <b-col cols="4">
                <b-input-group>
                    <multi-select v-model="valueSearch" :options="dataTaiKhoan" label="name" track-by="name"
                        selectLabel="Chọn" selectedLabel="Đã chọn" deselectLabel="Bỏ chọn" placeholder="Chọn Tài Khoản"
                        @input="thayDoiTaiKhoan()">
                        <span slot="noResult">Không tìm thấy kết quả tương ứng</span>
                    </multi-select>
                </b-input-group>
            </b-col>
            <b-col class="group-btn-action">
                <b-button :variant="$root.theme === 'dark' ? 'secondary' : 'light'" class="btn-action"
                    @click="themHinhAnh()">
                    <img :src="getIcon('add-circle')" class="mr-2" />
                    Thêm Hình Ảnh
                </b-button>
            </b-col>
        </b-row>
        <b-row cols="6" class="mt-3">
            <b-col cols="12">
                <b-table sticky-header :head-variant="$root.theme"
                    :table-variant="$root.theme === 'dark' ? 'secondary' : 'light'" :hover="true" :items="dataHinhAnh"
                    :fields="fieldsHinhAnh" show-empty empty-text="Không có dữ liệu" :current-page="currentPage"
                    :total-rows="totalRows" :per-page="perPage">
                    <template #cell(imageUrl)="data">
                        <label class="mr-1">{{ data.item.imageUrl }}</label>
                    </template>
                    <template #cell(createDate)="data">
                        {{ getFormatDate(data.item.createDate) }}
                    </template>
                    <template #cell(action)="data">
                        <div class="group-btn-action">
                            <b-button size="sm" :id="'btn-xoa-tai-khoan-' + data.index" variant="danger"
                                class="btn-action">
                                <img :src="getIcon('trash')" />
                            </b-button>
                            <b-popover :id="'popover-xoa-' + data.index" :ref="'popover-xoa-' + data.index"
                                :target="'btn-xoa-tai-khoan-' + data.index" triggers="focus"
                                custom-class="style-for-web">
                                <template #title>XÁC NHẬN XÓA HÌNH ẢNH</template>
                                <div class="msg-xac-nhan-xoa">
                                    Bạn Chắc Chắn Muốn Xóa Hình Ảnh
                                    <b>
                                        {{ data.item.imageName }}
                                    </b>
                                </div>
                                <hr>
                                <div class="btn-xac-nhan-xoa">
                                    <b-button :id="'btn-xac-nhan-ko-xoa-' + data.index" class="mr-1"
                                        @click="$refs['popover-xoa-' + data.index].$emit('close')">
                                        Không
                                    </b-button>
                                    <b-button :id="'btn-xac-nhan-co-xoa-' + data.index" variant="danger"
                                        @click="xoaHinhAnh(data.item, data.index)">
                                        Có
                                    </b-button>
                                </div>
                            </b-popover>
                        </div>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="6">
                Số lượng hình ảnh: {{ totalRows }}
            </b-col>
            <b-col cols="6">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" pills align="right">
                    <template #first-text>
                        <span>
                            <b-icon icon="chevron-bar-left"></b-icon>
                        </span>
                    </template>
                    <template #prev-text>
                        <span>
                            <b-icon icon="chevron-left"></b-icon>
                        </span>
                    </template>
                    <template #next-text>
                        <span>
                            <b-icon icon="chevron-right"></b-icon>
                        </span>
                    </template>
                    <template #last-text>
                        <span>
                            <b-icon icon="chevron-bar-right"></b-icon>
                        </span>
                    </template>
                </b-pagination>
            </b-col>
        </b-row>

        <b-modal id="modal-them-hinh-anh" title="THÊM HÌNH ẢNH" scrollable content-class="style-for-web">
            <div class="d-block">
                <b-row>
                    <b-col cols="12">
                        <label for="image_uploads" class="btn btn-primary">Chọn Hình Ảnh</label>
                        <b-form-file v-show="false" id="image_uploads" v-model="hinhAnhDaChon" class="mt-3" plain
                            multiple></b-form-file>
                    </b-col>
                </b-row>
                <b-row v-if="hinhAnhDaChon.length > 0">
                    <b-col>
                        <div v-for="(item, index) in hinhAnhDaChon" :key="index" class="mt-2 ml-2">
                            {{ item.name }}
                        </div>
                    </b-col>
                </b-row>
            </div>
            <template #modal-footer>
                <b-row class="w-100">
                    <b-col cols="4"></b-col>
                    <b-col cols="4">
                        <b-button class="mt-3" block @click="$bvModal.hide('modal-them-hinh-anh')">
                            Hủy
                        </b-button>
                    </b-col>
                    <b-col cols="4">
                        <b-button variant="primary" class="mt-3" block @click="submitThemXoaSua('them')">
                            Thêm
                        </b-button>
                    </b-col>
                </b-row>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import User from "../../../service/user.service";
import Image from "../../../service/image.service";

export default {
    name: "QuanLyHinhAnh",
    data() {
        return {
            valueSearch: '',
            dataTaiKhoan: [],
            dataHinhAnh: [],
            hinhAnhDaChon: [],
            fieldsHinhAnh: [
                { key: 'id', label: 'ID' },
                { key: 'imageName', label: 'Tên' },
                { key: 'imageUrl', label: 'URL' },
                { key: 'createDate', label: 'Ngày tải lên' },
                { key: 'action', label: '' },
            ],
            currentPage: 1,
            perPage: 15,
            totalRows: 0,
        };
    },
    computed: {
        ...mapState("QuanLyHinhAnh", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
        this.getUsers();
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
    },
    methods: {
        ...mapActions("QuanLyHinhAnh", []),
        getUsers() {
            let _this = this
            User.getAll(this.$root.url)
                .then((response) => {
                    _this.dataTaiKhoan = response.data;
                    _this.valueSearch = _this.dataTaiKhoan[0];
                    _this.getImages();
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        getImages() {
            let _this = this
            Image.getAll(this.$root.url, this.valueSearch.userName)
                .then((response) => {
                    _this.dataHinhAnh = response.data;
                    _this.totalRows = response.data.length;
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        thayDoiTaiKhoan() {
            console.log(this.valueSearch.userName);
            this.getImages();
        },
        getFormatDate(date) {
            return this.$moment(date).format(this.$root.formatDate);
        },
        themHinhAnh() {
            this.hinhAnhDaChon = [];
            this.$bvModal.show('modal-them-hinh-anh');
        },
        submitThemXoaSua(action, data, index) {
            let _this = this;
            switch (action) {
                case 'them':
                    for (const [index, file] of this.hinhAnhDaChon.entries()) {
                        data = new window.FormData();
                        data.append("userName", this.valueSearch.userName);
                        data.append("files", file, file.name);
                        Image.upload(this.$root.url, data)
                            .then(() => {
                                _this.showToast('Thêm thành công!', `Thêm hình ảnh "${file.name}"`, _this, 'success')
                                if (index === this.hinhAnhDaChon.length - 1) {
                                    _this.getImages();
                                    _this.$bvModal.hide('modal-them-hinh-anh');
                                }
                            }).catch((error) => {
                                _this.errorApi(error, _this);
                            });
                    }
                    break;
                case 'xoa':
                    Image.delete(this.$root.url, data)
                        .then(() => {
                            _this.getImages();
                            _this.showToast('Xóa thành công!', 'Xóa hình ảnh', _this, 'success')
                            _this.$refs['popover-xoa-' + index].$emit('close');
                        }).catch((error) => {
                            _this.errorApi(error, _this);
                        });
                    break;
            }
        },
        xoaHinhAnh(data, index) {
            this.submitThemXoaSua('xoa', data, index)
        },
    },
};
</script>

<style lang="scss" scoped>
.quan-ly-hinh-anh {
    padding: 20px;
    color: var(--text-color);
}
</style>
