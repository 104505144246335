import { render, staticRenderFns } from "./QuanLyHinhAnh.vue?vue&type=template&id=07a58fcc&scoped=true&"
import script from "./QuanLyHinhAnh.vue?vue&type=script&lang=js&"
export * from "./QuanLyHinhAnh.vue?vue&type=script&lang=js&"
import style0 from "./QuanLyHinhAnh.vue?vue&type=style&index=0&id=07a58fcc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07a58fcc",
  null
  
)

export default component.exports