<template>
    <div id="login" class="login">
        <div class="login-left">
            <div class="back-icon">
                <img :src="getIcon('back-icon', 'svg')" />
            </div>
            <div class="login-box">
                <div class="easy-scan">
                    <img :src="getIcon('logo', 'svg')" />
                </div>
                <div class="body-box mt-3 mb-4 p-3">
                    <b-row class="mb-3">
                        <b-col>
                            <div class="text-left">Tài Khoản</div>
                            <b-input-group>
                                <b-form-input v-model="userName"></b-form-input>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <b-col>
                            <div class="text-left">Mật Khẩu</div>
                            <b-input-group>
                                <b-form-input v-model="password" :type="isShowPass ? 'text' : 'password'">
                                </b-form-input>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <b-col>
                            <b-form-checkbox v-model="isShowPass" switch>
                                Hiện thị mật khẩu
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                </div>
                <div class="footer-box mt-3">
                    <div class="footer-btn mb-3 button-action">
                        <b-button variant="primary" size="lg" class="mt-3" @click="submit">
                            ĐĂNG NHẬP
                        </b-button>
                    </div>
                    <div class="footer-label mt-5">
                        Phần mền được phát triển bởi công ty EZ
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AuthRepository from "../../../service/authRepository.service";

export default {
    name: "LoginPage",
    data() {
        return {
            userName: "",
            password: "",
            isShowPass: false,
        };
    },
    computed: {
        ...mapState("LoginPage", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();

        let token = localStorage.getItem("jwt");
        if (token) {
            this.$router.push("quan-ly-tai-khoan");
        }
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
    },
    methods: {
        ...mapActions("LoginPage", []),
        showPass() {
            this.isShowPass = !this.isShowPass;
        },
        submit() {
            let _this = this;
            localStorage.setItem('loginTime', new Date());
            let param = {
                userName: _this.userName,
                password: _this.password,
            };
            AuthRepository.login(this.$root.url, param)
                .then((response) => {
                    let data = response.data;
                    localStorage.setItem("userName", data.userName);
                    localStorage.setItem("jwt", data.token);
                    localStorage.setItem("roles", data.roles);

                    let isAdmin = false;
                    if (data.roles.findIndex(i => i === 'ROLE_ADMIN') !== -1) {
                        isAdmin = true;
                    }

                    if (isAdmin) {
                        this.$router.push("quan-ly-tai-khoan");
                    } else {
                        this.$router.push("quan-ly-hinh-anh-for-member");
                    }

                    this.$root.isLogin = true;
                    this.$root.isAdmin = isAdmin;
                })
                .catch((error) => {
                    _this.errorApi(error, this);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.login {
    display: flex;

    .back-icon {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.2;
        z-index: 1;
    }

    .login-left {
        height: 100vh;
        width: 100vw;
        background-image: var(--background-image);
        background-size: cover;
        display: flex;
    }

    .login-box {
        width: 90%;
        margin: auto;
        padding: 10px;
        border-radius: 8px;
        background: var(--menu-bar-background);
        color: var(--text-color);
        z-index: 2;

        .easy-scan {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                height: 150px;
                width: 150px;
                background: var(--modal-body);
                border-radius: 50%;
            }
        }

        .input-login {
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;

            .vs-input-parent {
                width: 80%;

                input {
                    width: 100%;
                }
            }
        }

        .con-switch {
            display: flex;
            align-items: center;
            justify-content: center;

            .vs-switch {
                margin-right: 10px;
            }
        }

        .footer-box {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            button {
                margin-top: 5vh;
            }

            .footer-label {
                width: 100%;
                text-align: center;
                margin-top: 5vh;
            }
        }
    }
}
</style>
