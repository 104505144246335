import ApiService from "./api.service";

const authRepository = {
    getAll: async (url, userName) => {
        url = url + `user/getUsers`;
        if (userName) {
            url += `?userName=${userName}`
        }
        return ApiService.get(url);
    },
    insert: async (url, data) => {
        url = url + `user/insert`;
        return ApiService.put(url, data);
    },
    update: async (url, data) => {
        url = url + `user/update`;
        return ApiService.put(url, data);
    },
    delete: async (url, data) => {
        url = url + `user/delete`;
        return ApiService.delete(url, data);
    },
};

export default authRepository;
