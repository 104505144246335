import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
import VueMomentJS from "vue-momentjs";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Multiselect from 'vue-multiselect';
import VueViewer from 'v-viewer'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import 'viewerjs/dist/viewer.css'

Vue.config.productionTip = false;

moment.locale("vi");

Vue.use(VueMomentJS, moment);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueViewer);

// register globally
Vue.component('multi-select', Multiselect)

Vue.mixin({
    methods: {
        detectMob() {
            const toMatch = [
                /Android/i,
                /webOS/i,
                /iPhone/i,
                /iPad/i,
                /iPod/i,
                /BlackBerry/i,
                /Windows Phone/i
            ];
            
            return toMatch.some((toMatchItem) => {
                return navigator.userAgent.match(toMatchItem);
            });
        },
        movePage(path, method = "push") {
            let isMob = this.detectMob();
            console.log(isMob);
            path = isMob ? `mb-${path}` : path.replace('mb-', '');
            switch (method) {
                case "replace":
                    this.$router.replace(path).catch(()=>{});
                    break;
                default:
                    this.$router.push(path).catch(()=>{});
                    break;
            }
        },
        forceMovePage() {
            let routePath = this.$route.path;
            console.log(routePath);
            if ((this.$root.isMobile && !routePath.includes("mb-")) || 
                (!this.$root.isMobile && routePath.includes("mb-"))) {
                this.movePage(routePath.substring(1), "replace");
            }
        },
        switchHtmlTheme(theme) {
            let html = document.querySelector("html");
            html.dataset.theme = theme;
            this.$root.theme = theme
        },
        getIcon(icon, type) {
            return require(`./assets/${type ? type : "iconsax-svg"}/${icon}.svg`);
        },
        errorApi: (error, app) => {
			localStorage.setItem("loading", 0);
            let message = "";
            let code = "";
            if (error.response) {
                message = error.response.data.message;
                code = error.response.data.code;
            } else {
                message = error.message;
            }
            let title = `Lỗi ${code}`
            app.showToast(message, title, app, "danger");
        },
        showToast: (message, title, app, variant, append = false) => {
            app.$bvToast.toast(message, {
                title: title,
                autoHideDelay: 3000,
                appendToast: append,
                variant: variant,
                solid: true
            });
        }
    },
});

new Vue({
    router,
    store,
    render: (h) => h(App),
    data: {
        url: "https://be.scan.zf1.us/",
        theme: "light",
        isLogin: false,
        isMobile: false,
        isShowLoading: true,
        isAdmin: false,
        formatDate: "YYYY/MM/DD",
        formatDateTimeNoSecond: "YYYY/MM/DD HH:mm",
        formatDateTime: "YYYY/MM/DD HH:mm:ss",
        formatDateAPI: "YYYY-MM-DD",
        formatDateTimeNoSecondAPI: "YYYY-MM-DD HH:mm",
        formatDateTimeAPI: "YYYY-MM-DD HH:mm:ss",
        formatDateTimeFileName: "YYYY-MM-DD_HH-mm-ss",
    },
}).$mount("#app");
