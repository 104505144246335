<template>
    <div id="quan-ly-hinh-anh-for-member" class="quan-ly-hinh-anh-for-member">
        <b-row>
            <b-col cols="12">
                <div class="title-page">
                    Danh sách hình ảnh
                </div>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col cols="12">
                <viewer :option="optionsViewer" :images="dataHinhAnh" ref="viewer">
                    <template>
                        <figure class="images">
                            <div class="image-wrapper" v-for="(item, index) in dataHinhAnh" :key="index"
                                @click="viewImg">
                                <div class="image">
                                    <img :src="item.imageUrl" :data-source="item.imageUrl" :title="item.imageName" />
                                </div>
                                <label class="mt-1">{{ sliceString(item.imageName) }}</label>
                            </div>
                        </figure>
                    </template>
                </viewer>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Image from "../../../service/image.service";

export default {
    name: "QuanLyHinhAnh",
    data() {
        return {
            userName: '',
            dataHinhAnh: [],
            totalRows: 0,
            optionsViewer: {
                inline: false,
                button: true,
                navbar: true,
                title: true,
                toolbar: {
                    zoomIn: 10,
                    zoomOut: 10,
                    oneToOne: 0,
                    reset: 1,
                    prev: 1,
                    play: {
                        show: 0,
                        size: "large",
                    },
                    next: 1,
                    rotateLeft: 0,
                    rotateRight: 0,
                    flipHorizontal: 0,
                    flipVertical: 0,
                },
                tooltip: true,
                movable: true,
                zoomable: true,
                rotatable: false,
                scalable: false,
                transition: false,
                fullscreen: false,
                keyboard: false,
                url: "data-source",
            },
        };
    },
    computed: {
        ...mapState("QuanLyHinhAnh", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
        this.userName = localStorage.getItem('userName');
        this.getImages();
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
    },
    methods: {
        ...mapActions("QuanLyHinhAnh", []),
        getImages() {
            let _this = this
            Image.getAllForMember(this.$root.url, this.userName)
                .then((response) => {
                    _this.dataHinhAnh = response.data;
                    _this.totalRows = response.data.length;
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        sliceString(value) {
            var ext = value.split(".").slice(0, -1).join(".");
            var fileExt = value.split(".").pop();
            ext = ext.length > 20 ? ext.substring(0, 20) + "..." + fileExt : value;
            return ext;
        },
        viewImg(event) {
            if (event) {
                event.preventDefault();
                event.currentTarget.getElementsByTagName("img")[0].click();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.quan-ly-hinh-anh-for-member {
    padding: 20px;
    color: var(--text-color);

    .title-page {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 30px;
    }

    .images {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        .image-wrapper {
            width: calc(50% - 20px);
            height: 20vh;
            background: var(--back-color-broup-btn-tab);
            padding: 10px;
            margin: 10px;
            border-radius: 8px;
            text-align: center;
            cursor: pointer;
            box-shadow: var(--box-shadow);

            &:hover {
                .image {
                    img {
                        width: 120%;
                    }
                }
            }

            .image {
                width: 100%;
                height: 65%;
                overflow: hidden;
                border-radius: 8px;

                img {
                    width: 100%;
                    transition: all 0.5s;
                }
            }

            label {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }
}
</style>
