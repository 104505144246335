<template>
    <div id="app" class="app" :class="$root.isMobile ? 'style-for-mobbile' : 'style-for-web'">
        <div :class="isLogin ? '' : 'not-login'">
            <div v-if="isLogin && !$root.isMobile" class="header-page">
                <div class="title-page">
                    <img :src="getIcon('logo', 'svg')" />
                    Hệ Thống Quản Lý Hình Ảnh Vi Phạm
                </div>
                <div class="action">
                    <b-button-group>
                        <b-button :variant="$root.theme === 'dark' ? 'secondary' : 'light'">
                            <img :src="getIcon('user-octagon')" />
                            {{ name }}
                        </b-button>
                        <div class="group-theme "
                            :class="{ 'btn-light': $root.theme !== 'dark', 'btn-secondary': $root.theme === 'dark' }">
                            <img :src="getIcon('sun')" />
                            <b-form-checkbox v-model="isDarkMode" name="check-button" switch
                                @change="setThemePreference(isDarkMode ? 'dark' : 'light')">
                            </b-form-checkbox>
                            <img :src="getIcon('moon')" />
                        </div>
                        <b-button variant="danger" @click="logout()">
                            <img :src="getIcon('logout')" />
                        </b-button>
                    </b-button-group>
                </div>
            </div>
            <div v-if="isLogin && $root.isMobile" class="header-page">
                <div class="title-page">
                    <img :src="getIcon('logo', 'svg')" />
                    HTQL Hình Ảnh Vi Phạm
                </div>
                <div class="action">
                    <b-button-group>
                        <b-button size="sm" id="user-info" :variant="$root.theme === 'dark' ? 'secondary' : 'light'">
                            <img :src="getIcon('user-octagon')" />
                        </b-button>
                        <b-button size="sm" variant="danger" @click="logout()">
                            <img :src="getIcon('logout')" />
                        </b-button>
                        <b-popover target="user-info" triggers="focus" custom-class="style-for-mobbile"
                            placement="bottomleft">
                            <template #title>
                                {{ name }}
                            </template>
                            <div class="group-theme">
                                <img :src="getIcon('sun')" />
                                <b-form-checkbox v-model="isDarkMode" name="check-button" switch
                                    @change="setThemePreference(isDarkMode ? 'dark' : 'light')">
                                </b-form-checkbox>
                                <img :src="getIcon('moon')" />
                            </div>
                        </b-popover>
                    </b-button-group>
                </div>
            </div>
            <div v-if="isLogin && $root.isAdmin" class="group-btn-tab mt-2">
                <div>
                    <b-button size="sm" :variant="isActiveQLTK ? 'secondary' : 'light'" class="mr-1"
                        @click="moveToPage('quan-ly-tai-khoan')">
                        <img :src="getIcon('user-square')" />
                        Quản Lý Tài Khoản
                    </b-button>
                    <b-button size="sm" :variant="isActiveQLHA ? 'secondary' : 'light'" class="ml-1"
                        @click="moveToPage('quan-ly-hinh-anh')">
                        <img :src="getIcon('picture-frame')" />
                        Quản Lý Hình Ảnh
                    </b-button>
                </div>
            </div>

            <div class="content">
                <router-view />
            </div>
        </div>
        <div class="loading-icon" v-if="isLoading === '1'">
            <b-spinner style="width: 3rem; height: 3rem" label="Large Spinner"></b-spinner>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: "LoginPage",
    data() {
        return {
            name: "",
            isLoading: false,
            isDarkMode: false,
            isActiveQLTK: true,
            isActiveQLHA: false,
        };
    },
    watch: {
        "$root.isLogin": {
            handler(val) {
                this.isLogin = val;

                let roles = localStorage.getItem('roles')
                if (roles.includes('ROLE_ADMIN')) {
                    this.$root.isAdmin = true;
                }

                this.checkLogin();
                this.checkActiveItem();
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {},
    created() {
        console.log("I am mounted to the DOM!");
        this.$root.isMobile = this.detectMob();
        this.setThemePreference();

        let _this = this;
        setInterval(() => {
            _this.isLoading = _this.$root.isShowLoading ? localStorage.getItem("loading") : 0;

            let now = new Date();
            let loginTime = localStorage.getItem("loginTime");
            let theme = localStorage.getItem("themeColor") || 'light';
            if (moment(now).isAfter(moment(loginTime).add(24, 'h'))) {
                _this.showToast("Xin đăng nhập lại", "Hết giờ!", _this, "danger");
                localStorage.clear();
                localStorage.setItem("themeColor", theme);
                _this.$root.isLogin = false;
                _this.isLogin = false
                _this.movePage("login", "replace");
                clearInterval();
            }
        }, 100);

        let token = localStorage.getItem("jwt");
        if (token) {
            this.$root.isLogin = true;
            this.isLogin = this.$root.isLogin;
            this.checkActiveItem();
        } else {
            let theme = localStorage.getItem("themeColor") || 'light';
            localStorage.clear();
            localStorage.setItem("themeColor", theme);
            this.$root.isLogin = false;
            this.isLogin = false
            this.movePage("login", "replace");
        }
    },
    methods: {
        setThemePreference(mode) {
            console.log(mode);
            if (mode) {
                localStorage.setItem('themeColor', mode);
                this.switchHtmlTheme(mode);
            } else {
                let theme = localStorage.getItem('themeColor') || 'light';
                this.isDarkMode = theme === 'dark';
                this.switchHtmlTheme(theme);
            }
        },
        checkLogin() {
            let userName = localStorage.getItem("userName");
            this.name = userName ? userName : "";
            console.log(this.name);
        },
        logout() {
            let theme = localStorage.getItem('themeColor') || 'light';
            localStorage.clear();
            localStorage.setItem('themeColor', theme);
            this.isLogin = false;
            this.$root.isLogin = false;
            this.movePage("login", "replace");
        },
        moveToPage(path) {
            this.movePage(path);
            this.checkActiveItem();
        },
        checkActiveItem() {
            let routePath = this.$route.path;
            this.isActiveQLTK = routePath.includes('tai-khoan');
            this.isActiveQLHA = routePath.includes('hinh-anh');
        },
    },
};
</script>

<style lang="scss">
@import './assets/scss/theme/light.scss';
@import './assets/scss/theme/dark.scss';
@import './assets/scss/style-for-mobbile.scss';
@import './assets/scss/style-for-web.scss';

body {
    background: var(--back-color-app);
    height: 100vh;
    width: 100%;
    color: var(--text-color);

    [class^="col"],
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12,
    .col,
    .col-auto,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm,
    .col-sm-auto,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md,
    .col-md-auto,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg,
    .col-lg-auto,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl,
    .col-xl-auto {
        padding-right: 5px;
        padding-left: 5px;
    }

    .btn-primary {
        color: var(--text-btn-primary-color);
        background-color: var(--btn-primary-color);
        border-color: var(--btn-primary-color);

        img {
            filter: var(--white-color-icon) !important;
        }

        &:hover {
            color: var(--text-btn-hover);
            background-color: var(--btn-primary-color-hover);
            border-color: var(--btn-primary-color-hover);
        }
    }

    .btn-secondary {
        color: var(--text-btn-secondary-color);
        background-color: var(--btn-secondary-color);
        border-color: var(--btn-secondary-color);

        &:hover {
            color: var(--text-btn-hover);
            background-color: var(--btn-secondary-color-hover);
            border-color: var(--btn-secondary-color-hover);
        }
    }

    .btn-light {
        color: var(--text-btn-light-color);
        background-color: var(--btn-light-color);
        border-color: var(--btn-light-color);

        &:hover {
            color: var(--text-btn-light-hover);
            background-color: var(--btn-light-color-hover);
            border-color: var(--btn-light-color-hover);
        }
    }

    .btn-danger {
        color: var(--text-btn-danger-color);
        background-color: var(--btn-danger-color);
        border-color: var(--btn-danger-color);

        &:hover {
            color: var(--text-btn-hover);
            background-color: var(--btn-danger-color-hover);
            border-color: var(--btn-danger-color-hover);
        }
    }

    .btn-success {
        color: var(--text-btn-success-color);
        background-color: var(--btn-success-color);
        border-color: var(--btn-success-color);

        &:hover {
            color: var(--text-btn-hover);
            background-color: var(--btn-success-color-hover);
            border-color: var(--btn-success-color-hover);
        }
    }

    .btn-warning {
        color: var(--text-btn-warning-color);
        background-color: var(--btn-warning-color);
        border-color: var(--btn-warning-color);

        &:hover {
            color: var(--text-btn-hover);
            background-color: var(--btn-warning-color-hover);
            border-color: var(--btn-warning-color-hover);
        }
    }

    .btn-info {
        color: var(--text-btn-info-color);
        background-color: var(--btn-info-color);
        border-color: var(--btn-info-color);

        &:hover {
            color: var(--text-btn-hover);
            background-color: var(--btn-info-color-hover);
            border-color: var(--btn-info-color-hover);
        }
    }

    .show .dropdown-toggle {
        color: var(--text-color) !important;

        &:focus {
            color: var(--text-color) !important;
        }
    }

    .dropdown-menu {
        background-color: var(--model-background) !important;
        color: var(--text-color) !important;
        box-shadow: var(--box-shadow);
    }
}
</style>
