<template>
    <div id="quan-ly-tai-khoan" class="quan-ly-tai-khoan">
        <b-row>
            <b-col cols="4">
                <b-input-group class="input-search">
                    <template #prepend>
                        <b-input-group-text>
                            <img :src="getIcon('search-normal')" />
                        </b-input-group-text>
                    </template>
                    <b-form-input v-model="valueSearch"></b-form-input>
                </b-input-group>
            </b-col>
            <b-col class="group-btn-action">
                <b-button :variant="$root.theme === 'dark' ? 'secondary' : 'light'" class="btn-action"
                    @click="themTaiKhoan()">
                    <img :src="getIcon('add-circle')" class="mr-2" />
                    Thêm Tài Khoản
                </b-button>
            </b-col>
        </b-row>
        <b-row cols="6" class="mt-3">
            <b-col cols="12">
                <b-table sticky-header :head-variant="$root.theme"
                    :table-variant="$root.theme === 'dark' ? 'secondary' : 'light'" :hover="true"
                    :items="dataTaiKhoanDaLoc" :fields="fieldsTaiKhoan" show-empty empty-text="Không có dữ liệu"
                    :current-page="currentPage" :total-rows="totalRows" :per-page="perPage">
                    <template #cell(delFlg)="data">
                        {{ data.item.delFlg === 0 ? 'Hoạt Động' : 'Ngừng Hoạt Động' }}
                    </template>
                    <template #cell(updDate)="data">
                        {{ getFormatDate(data.item.updDate) }}
                    </template>
                    <template #cell(action)="data">
                        <div class="group-btn-action">
                            <b-button size="sm" variant="dark" class="btn-action mr-2" @click=suaTaiKhoan(data.item)>
                                <img :src="getIcon('edit')" />
                            </b-button>
                            <b-button size="sm" :id="'btn-xoa-tai-khoan-' + data.index" variant="danger"
                                class="btn-action">
                                <img :src="getIcon('trash')" />
                            </b-button>
                            <b-popover :id="'popover-xoa-' + data.index" :ref="'popover-xoa-' + data.index"
                                :target="'btn-xoa-tai-khoan-' + data.index" triggers="focus"
                                custom-class="style-for-web">
                                <template #title>XÁC NHẬN XÓA TÀI KHOẢN</template>
                                <div class="msg-xac-nhan-xoa">
                                    Bạn Chắc Chắn Muốn Xóa Tài Khoản
                                    <b>
                                        {{ `${data.item.name} (${data.item.userName})` }}
                                    </b>
                                </div>
                                <hr>
                                <div class="btn-xac-nhan-xoa">
                                    <b-button :id="'btn-xac-nhan-ko-xoa-' + data.index" class="mr-1"
                                        @click="$refs['popover-xoa-' + data.index].$emit('close')">
                                        Không
                                    </b-button>
                                    <b-button :id="'btn-xac-nhan-co-xoa-' + data.index" variant="danger"
                                        @click="xoaTaiKhoan(data.item, data.index)">
                                        Có
                                    </b-button>
                                </div>
                            </b-popover>
                        </div>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="6">
                Số lượng tài khoản: {{ totalRows }}
            </b-col>
            <b-col cols="6">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" pills align="right">
                    <template #first-text>
                        <span>
                            <b-icon icon="chevron-bar-left"></b-icon>
                        </span>
                    </template>
                    <template #prev-text>
                        <span>
                            <b-icon icon="chevron-left"></b-icon>
                        </span>
                    </template>
                    <template #next-text>
                        <span>
                            <b-icon icon="chevron-right"></b-icon>
                        </span>
                    </template>
                    <template #last-text>
                        <span>
                            <b-icon icon="chevron-bar-right"></b-icon>
                        </span>
                    </template>
                </b-pagination>
            </b-col>
        </b-row>

        <b-modal id="modal-them-tai-khoan" :title="userId ? 'SỬA TÀI KHOẢN' : 'THÊM TÀI KHOẢN'" scrollable
            content-class="style-for-web">
            <div class="d-block">
                <b-row>
                    <b-col cols="12">
                        <div class="text-left">ID Tài Khoản</div>
                        <b-input-group class="mb-3">
                            <b-form-input v-model="userName" :disabled="userId !== ''">
                            </b-form-input>
                            <b-input-group-append v-if="!userId">
                                <b-button variant="primary" @click="taoTuDong('userName')">
                                    Tạo
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <div class="text-left">Mật Khẩu</div>
                        <b-input-group class="mb-3">
                            <b-form-input v-model="password" :disabled="userId !== ''">
                            </b-form-input>
                            <b-input-group-append v-if="!userId">
                                <b-button variant="primary" @click="taoTuDong('password')">
                                    Tạo
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <div class="text-left">Tên Tài Khoản</div>
                        <b-input-group class="mb-3">
                            <b-form-input v-model="name">
                            </b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <div class="text-left">Ghi Chú</div>
                        <b-input-group class="mb-3">
                            <b-form-input v-model="note">
                            </b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
            </div>
            <template #modal-footer>
                <b-row class="w-100">
                    <b-col cols="4"></b-col>
                    <b-col cols="4">
                        <b-button class="mt-3" block @click="$bvModal.hide('modal-them-tai-khoan')">Hủy</b-button>
                    </b-col>
                    <b-col cols="4">
                        <b-button variant="primary" class="mt-3" block
                            @click="submitThemXoaSua(userId ? 'sua' : 'them')">
                            {{ userId ? 'Lưu' : 'Thêm' }}
                        </b-button>
                    </b-col>
                </b-row>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import User from "../../../service/user.service";

export default {
    name: "QuanLyTaiKhoan",
    data() {
        return {
            valueSearch: '',
            characters: [
                {
                    name: "Lowercase",
                    value: "abcdefghijklmnopqrstuvwxyz",
                },
                {
                    name: "Uppercase",
                    value: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
                },
                {
                    name: "Numbers",
                    value: "0123456789",
                },
            ],
            gLength: 8,
            dataTaiKhoan: [],
            fieldsTaiKhoan: [
                { key: 'id', label: 'ID' },
                { key: 'name', label: 'Tên TK' },
                { key: 'userName', label: 'ID TK' },
                { key: 'password', label: 'Mật khẩu' },
                { key: 'delFlg', label: 'Trạng thái' },
                { key: 'note', label: 'Ghi chú' },
                { key: 'imageCount', label: 'SL Hình ảnh' },
                { key: 'updDate', label: 'Ngày cập nhật' },
                { key: 'action', label: '' },
            ],
            currentPage: 1,
            perPage: 15,
            userId: '',
            userName: '',
            name: '',
            password: '',
            note: '',
        };
    },
    computed: {
        ...mapState("QuanLyTaiKhoan", []),
        dataTaiKhoanDaLoc() {
            if (this.valueSearch !== "" && this.valueSearch.length >= 2) {
                return this.dataTaiKhoan.filter((row) => {
                    const userNameSearch = row.name.toLowerCase();
                    const loginSearch = row.userName.toLowerCase();
                    const searchTerm = this.valueSearch.toLowerCase();
                    return (
                        userNameSearch.includes(searchTerm) ||
                        loginSearch.includes(searchTerm)
                    );
                });
            } else {
                return this.dataTaiKhoan;
            }
        },
        totalRows() {
            return this.dataTaiKhoanDaLoc.length;
        }
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
        this.getUsers();
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
    },
    methods: {
        ...mapActions("QuanLyTaiKhoan", []),
        getUsers() {
            let _this = this
            User.getAll(this.$root.url, this.valueSearch)
                .then((response) => {
                    _this.dataTaiKhoan = response.data;
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        getFormatDate(date) {
            return this.$moment(date).format(this.$root.formatDate);
        },
        themTaiKhoan() {
            this.userId = '';
            this.userName = '';
            this.name = '';
            this.password = '';
            this.note = '';
            this.$bvModal.show('modal-them-tai-khoan');
        },
        suaTaiKhoan(data) {
            this.userId = data.id;
            this.userName = data.userName;
            this.name = data.name;
            this.password = data.password;
            this.note = data.note;
            this.$bvModal.show('modal-them-tai-khoan');
        },
        submitThemXoaSua(action, data, index) {
            let _this = this;
            switch (action) {
                case 'them':
                    data = {
                        userName: this.userName,
                        name: this.name,
                        password: this.password,
                        note: this.note,
                    }
                    User.insert(this.$root.url, data)
                        .then(() => {
                            _this.getUsers();
                            _this.showToast('Thêm thành công!', 'Thêm Tài Khoản', _this, 'success')
                            _this.$bvModal.hide('modal-them-tai-khoan');
                        }).catch((error) => {
                            _this.errorApi(error, _this);
                        });
                    break;
                case 'xoa':
                    User.delete(this.$root.url, data)
                        .then(() => {
                            _this.getUsers();
                            _this.showToast('Xóa thành công!', 'Xóa Tài Khoản', _this, 'success')
                            _this.$refs['popover-xoa-' + index].$emit('close');
                        }).catch((error) => {
                            _this.errorApi(error, _this);
                        });
                    break;
                case 'sua':
                    data = {
                        userId: this.userId,
                        userName: this.userName,
                        name: this.name,
                        password: this.password,
                        note: this.note,
                    }
                    User.update(this.$root.url, data)
                        .then(() => {
                            _this.getUsers();
                            _this.showToast('Sửa thành công!', 'Sửa Tài Khoản', _this, 'success')
                            _this.$bvModal.hide('modal-them-tai-khoan');
                        }).catch((error) => {
                            _this.errorApi(error, _this);
                        });
                    break;
            }
        },
        xoaTaiKhoan(data, index) {
            this.submitThemXoaSua('xoa', data, index)
        },
        taoTuDong(type) {
            let result = "";
            let charactersVal = "";
            switch (type) {
                case 'userName':
                    for (var a = 0; a < this.characters.length; a++) {
                        charactersVal += this.characters[a].value;
                    }
                    for (var b = 0; b < this.gLength; b++) {
                        result += charactersVal.charAt(
                            Math.floor(Math.random() * charactersVal.length)
                        );
                    }
                    this.userName = result;
                    break;
                case 'password':
                    for (var c = 0; c < this.characters.length; c++) {
                        charactersVal += this.characters[c].value;
                    }
                    for (var d = 0; d < this.gLength; d++) {
                        result += charactersVal.charAt(
                            Math.floor(Math.random() * charactersVal.length)
                        );
                    }
                    this.password = result;
                    break;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.quan-ly-tai-khoan {
    padding: 20px;
    color: var(--text-color);
}
</style>
