import ApiService from "../service/api.service";
import Vue from "vue";
import VueRouter from "vue-router";

// Web
import LoginPage from "../components/Web/Login/Login.vue";
import QuanLyTaiKhoanPage from "../components/Web/QuanLy/QuanLyTaiKhoan.vue";
import QuanLyHinhAnhPage from "../components/Web/QuanLy/QuanLyHinhAnh.vue";
import QuanLyHinhAnhForMemberPage from "../components/Web/QuanLy/QuanLyHinhAnhForMember.vue";

// Mobile
import LoginMobilePage from "../components/Mobile/Login/Login.vue";
import QuanLyTaiKhoanMobilePage from "../components/Mobile/QuanLy/QuanLyTaiKhoan.vue";
import QuanLyHinhAnhMobilePage from "../components/Mobile/QuanLy/QuanLyHinhAnh.vue";
import QuanLyHinhAnhForMemberMobilePage from "../components/Mobile/QuanLy/QuanLyHinhAnhForMember.vue";


Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: "/",
      component: LoginPage,
    },
    {
      path: "/login",
      component: LoginPage,
    },
    {
      path: "/quan-ly-tai-khoan",
      component: QuanLyTaiKhoanPage,
      meta: {
        requiresAuth: true,
        role: "ROLE_ADMIN",
      },
    },
    {
      path: "/quan-ly-hinh-anh",
      component: QuanLyHinhAnhPage,
      meta: {
        requiresAuth: true,
        role: "ROLE_ADMIN",
      },
    },
    {
      path: "/quan-ly-hinh-anh-for-member",
      component: QuanLyHinhAnhForMemberPage,
      meta: {
        requiresAuth: true,
        role: "ROLE_MEMBER",
      },
    },
    // Mobile
    {
      path: "/mb",
      component: LoginMobilePage,
    },
    {
      path: "/mb-login",
      component: LoginMobilePage,
    },
    {
      path: "/mb-quan-ly-tai-khoan",
      component: QuanLyTaiKhoanMobilePage,
      meta: {
        requiresAuth: true,
        role: "ROLE_ADMIN",
      },
    },
    {
      path: "/mb-quan-ly-hinh-anh",
      component: QuanLyHinhAnhMobilePage,
      meta: {
        requiresAuth: true,
        role: "ROLE_ADMIN",
      },
    },
    {
      path: "/mb-quan-ly-hinh-anh-for-member",
      component: QuanLyHinhAnhForMemberMobilePage,
      meta: {
        requiresAuth: true,
        role: "ROLE_MEMBER",
      },
    },
  ],
});
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("jwt");
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (token == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      if (!ApiService.checkTokenValidity(token)) {
        let theme = localStorage.getItem("themeColor") || 'light';
        localStorage.clear();
        localStorage.setItem("themeColor", theme);
        next({ name: "login" });
      } else {
        let roles = localStorage.getItem("roles");
        if (to.matched.some((record) => record.meta.role)) {
          if (roles.includes(to.meta.role)) {
            next();
          } else {
            next(false);
          }
        } else {
          next();
        }
      }
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (token == null) {
      next();
    } else {
      if (!ApiService.checkTokenValidity(token)) {
        let theme = localStorage.getItem("themeColor") || 'light';
        localStorage.clear();
        localStorage.setItem("themeColor", theme);
        next({ name: "login" });
      } else {
        next({ name: "quan-ly-hinh-anh-for-member" });
      }
    }
  } else {
    next();
  }
});
export default router;
